import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import "../assets/css/global.css"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

const Error = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>404 Error</title>
        <link rel="canonical" href="https://filmchronicle.com/404" />
      </Helmet>
      <Seo title="Error" />
      <main className="error-page" style={{backgroundColor: "#fff"}}>
        <section>  
          <div className="container-lg">
            <h2 className="mb-25" style={{paddingTop: "25px"}}>Looks like you got lost</h2>
            <p className="mb-15" style={{color: "#666666"}}>There’s plenty more to explore. Start with our <Link style={{textDecoration: "underline"}} to="/">homepage</Link>, or skip straight to the <Link style={{textDecoration: "underline"}} to="/watch/film-library">film library</Link>.</p>
            <p className="mb-15" style={{color: "#666666"}}>Need help finding something? Email us at <a style={{textDecoration: "underline"}} href="mailto:contact@filmchronicle.com">contact@filmchronicle.com</a>.</p>
            <p className="mb-15" style={{color: "#666666"}}>Or, go make yourself a drink and we’ll have our team work on this page in two shakes and a lamb’s tail.</p>
          </div>
          <StaticImage
            src="../assets/images/pulp-fiction.png"
            alt="404 page image"
            placeholder="tracedSVG"
            layout="fullWidth"
          ></StaticImage>
        </section>
      </main>
    </Layout>
  )
}

export default Error